@import "@yaireo/tagify/dist/tagify.css";

// for model > Google Autocomplete input
.pac-container {
  z-index: 99999 !important;
}

.react-datepicker-popper {
  z-index: 1001;
}
.spinner {
  display: block;
  position: fixed;
  z-index: 1031; /* High z-index so it is on top of the page */
  top: 50%;
  right: 50%; /* or: left: 50%; */
  margin-top: -20px; /* half of the elements height */
  margin-right: -20px; /* half of the elements width */
}
.invalid {
  color: #e85347;
  font-size: 11px;
  font-style: italic;
}
.custom-underline {
  &:hover {
    text-decoration: underline;
    text-decoration-color: #95baff;
  }
}
.vendor-list {
  overflow: hidden !important;
  height: 80px !important;
  width: 80px !important;
}

.swal2-styled.swal2-confirm {
  background-color: $primary !important;
}

.cursor {
  cursor: pointer;
}

.fSize14 {
  font-size: 14px !important;
}
.long-logo {
  width: 155px !important;
  height: 32px !important;
}
.hide-on-hover {
  visibility: hidden;
}
.hover-table-row {
}
.hover-table-row:hover .hide-on-hover {
  visibility: visible;
}
.sub-text-line-2 {
  overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  height: 3em !important;
}
.outline-user-avatar {
  outline: 1px solid #c2c2c2;
}

.overflow-x-auto {
  overflow-x: auto !important;
}
.w-450 {
  width: 450px;
}
.h-350 {
  height: 350px;
}
.right-20 {
  right: 20px;
}
.bottom-20 {
  bottom: 20px;
}
.chat-section {
  border-radius: 14px !important;
  z-index: 10001;
  .card-inner {
    padding: 0 12px 0 12px !important;
  }
  .nk-chat-head {
    padding: 12px 0 12px 0 !important;
  }
  .nk-chat-editor {
    padding: 12px 0 12px 0 !important;
  }
  .nk-chat-body {
    position: static !important;
    opacity: 1 !important;
    pointer-events: auto !important;
  }
  @media (max-width: 859px) {
    width: 60%;
  }
  @media (max-width: 550px) {
    width: 80%;
  }
}
@include media-breakpoint-up(lg) {
  .nk-auth {
    &-container {
      width: 50%;
    }
  }
}
@media only screen and (max-width: 650px) {
  .mobile-table-view {
    display: block !important;
    overflow-x: auto !important;
  }
}
.outline-none {
  outline: none !important;
}
.w-500 {
  width: 500px !important;
}

.max-w-856 {
  max-width: 856px !important;
}

.w-150 {
  width: 150px !important;
}

.credit-card-name {
  color: "#7e8ca3";
  font-weight: "400";
  // font-family: "Roboto, Open Sans, Segoe UI, sans-serif";
  font-family: "Times New Roman", Times, serif;
  font-size: "14px";

  ::placeholder {
    color: #dbdfea !important;
  }
  :-webkit-autofill {
    color: "#7e8ca3" !important;
  }
}
.altitude-modal .close .icon {
  color: #364a63 !important;
  &:hover {
    color: black !important;
  }
}
.h-40 {
  height: 40px !important;
}
.white-space-nowrap {
  white-space: nowrap !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.css-1pahdxg-control {
  border-color: #2684ff !important;
  box-shadow: 0 0 0 1px #2684ff !important;
}
.wizard-margin {
  flex-grow: 0 !important;
  padding-left: 10px;
  padding-right: 10px;
}
.nk-tb-item {
  transition: none !important;
}
.card-inner-group .card-inner:not(:last-child) {
  border-bottom: none !important;
}
.remove-shadow {
  box-shadow: none;
}

.image-slider-view .project-progress {
  max-width: 100% !important;
}
.slick-track {
  margin-left: 0 !important;
}
.white-text-button {
  color: #ffffff !important;
  &:hover {
    color: #d2d2d2 !important;
  }
}
.lateFeeWidth {
  width: 109px !important;
}

.h-445px {
  height: 500px !important;
}

.line-break-anywhere {
  line-break: anywhere !important;
}

.fs-12 {
  font-size: 12px !important;
}
.min-w-36 {
  min-width: 36px !important;
}
.w-200 {
  width: 200px !important;
}
.h-120 {
  height: 120px !important;
}
.light-blue {
  background-color: #e9f2fe !important;
}
.min-w-30 {
  min-width: 30px !important;
}
.min-h-30 {
  min-height: 30px !important;
}
.top-4 {
  top: 4px !important;
}
.right-4 {
  right: 4px !important;
}
.z-15 {
  z-index: 15 !important;
}
.mt-n20 {
  margin-top: -20px !important;
}
.min-h-60 {
  min-height: 60px !important;
}

// table------------------------->
.rdt_Table {
  margin: 0px !important;
  overflow-x: auto;
  border: none !important;
}

.rdt_TableHeadRow {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.rdt_TableRow {
  min-height: 68px !important;
  font-size: 14px !important;
  &:hover {
    background: #f8f9fc !important;
    box-shadow: 0 2px 15px -4px rgba(44, 117, 255, 0.4) !important;
  }
  &:last-child {
    border-radius: 10px;
  }
}

.date-range-picker {
  flex-wrap: nowrap !important;
  // display: flex;
}

.overflow-visiable {
  overflow: visible !important;
}

.rdt_TableCol .rdt_TableCol_Sortable {
  width: fit-content !important;
  gap: 5px !important;
}

.left-header {
  justify-content: flex-start !important;
  gap: 5px !important;
}

.right-header {
  justify-content: flex-end !important;
  gap: 5px !important;
}
.play-icon {
  position: absolute !important;
  font-size: 60px !important;
  top: 50%;
  right: 50%;
  margin-top: -30px; /* half of the elements height */
  margin-right: -30px; /* half of the elements width */
}

// tagify----------------------->
.tagify {
  align-items: center !important;
  padding: 0px !important;
  min-height: 36px;
  border-radius: 4px !important;
}
.dropzone {
  padding: 0px;
  position: relative;
}
.dropzon-button {
  padding: 0px;
  position: absolute;
  height: 100%;
  width: 100%;
}
.tagify__tag > div::before {
  box-shadow: 0 0 0 1.1em #2c75ff26 inset !important;
}
.tagify__tag.tagify--notAllowed:not(.tagify__tag--editable) div::before {
  box-shadow: 0 0 0 1.1em rgb(255 0 0 / 25%) inset !important;
}
.tagify__tag-text {
  line-height: 16px !important;
}

// custome radio button-------------->
.radio-check-custom {
  opacity: 0;
  position: absolute;
}
.radio-check-custom,
.radio-check-custom-label {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}

.radio-check-custom-label {
  position: relative;
}
.radio-check-custom + .radio-check-custom-label:before {
  content: "";
  background: #fff;
  border: 2px solid #ddd;
  display: inline-flex;
  vertical-align: middle;
  // display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
}
.radio-check-custom + .radio-check-custom-label:before {
  // border-radius: 50%;
}

.radio-check-custom:checked + .radio-check-custom-label:before {
  content: "\2713";
  // font-family: 'FontAwesome';
  position: relative;
  color: white;
  // position: absolute;
  border: none !important;
  background-color: #2684ff;
}
.radio-check-custom:focus + .radio-check-custom-label {
  //   outline: 1px solid #ddd;
  border: none !important;
}

.tags-input .tagify__tag {
  line-height: inherit !important;
}

.comment-send-button {
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
}

.comment-section-height {
  height: 500px;
}

.margin {
  &-y {
    &-50 {
      margin-top: 50px !important;
      margin-bottom: 50px !important;
    }
  }
}
