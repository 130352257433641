.circle {
    border-radius: 50%;
}
.round{
    border-radius: $border-radius;
    &-sm{
        border-radius: $border-radius-sm;
    }
    &-lg{
        border-radius: $border-radius-lg;
    }
    &-xl{
        border-radius: $border-radius-lg * 2;
    }
}
svg{
    height: 100%;
    max-width: 100%;
}
.sq{
    height: 40px;
    width: 40px;
    &-xs{
        height: 18px;
        width: 18px;
    }
    &-sm{
        height: 24px;
        width: 24px;
    }
    &-md{
        height: 32px;
        width: 32px;
    }
    &-lg{
        height: 60px;
        width: 60px;
    }
    &-xl{
        height: 80px;
        width: 80px;
    }
}
.divider {
    border-top: 1px solid $border-light !important;
    margin: $gutter-width 0;
    &.xs {
        margin: .5rem 0;
    }
    &.sm {
        margin: 1rem 0;
    }
    &.md {
        margin: 1.5rem 0;
    }
    &.lg {
        margin: 2.25rem 0;
    }
    &.xl {
        margin: 2.75rem 0;
    }
    
}