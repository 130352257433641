/* LoadingComponent.css */

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
    background-color: rgba(255, 255, 255, 0.9);    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-overlay-fill-bg {
    background-color: rgba(255, 255, 255, 1);
  }

  .loading-spinner-container {
    width: 80px;
    height: 80px;
    position: relative;
  }
  
  .loading-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  
  .loading-spinner div {
    width: 10px;
    height: 20px;
    background-color: #2C75FF;
    border-radius: 10px;
    animation: loading-spinner-animation 1.0s ease-in-out infinite;
  }
  
  .loading-spinner div:nth-child(1) {
    /* background-color: #2263b35a; */
    animation-delay: -0.64s;
  }
  
  .loading-spinner div:nth-child(2) {
    /* background-color: #2263b35a; */
    animation-delay: -0.48s;
  }
  
  .loading-spinner div:nth-child(3) {
    /* background-color: #2263b35a; */
    animation-delay: -0.32s;
  }
  
  .loading-spinner div:nth-child(4) {
    /* background-color: #2263b35a;  */
    animation-delay: -0.16s;
  }
  
  
  @keyframes loading-spinner-animation {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1, 2.5);
    }
    40% {
      transform: scale(1);
    }
  }
  